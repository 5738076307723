import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";

import "utils/fixFontAwesome";
import breakDownAllNodes from "utils/breakDownAllNodes";
import Wrapper from "containers/Wrapper";
import ImageCard from "components/ImageCard";

import "../style/main.scss";

export const query = graphql`
  query IndexQuery($langKey: String!) {
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey } } }
      sort: { order: ASC, fields: [fields___directoryName, fields___fileName] }
    ) {
      nodes {
        frontmatter {
          imageFileName
          anchor
          sections {
            anchor
          }
          copyright
          social {
            linkedin
          }
          privacyText
          termsText
          
          titleTemplate
          keywords
          description
          
          header
          subheader
          text
          jumpToAnchorText
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const IndexPage = ({ pathContext, data }) => {
  const { allMarkdownRemark: { nodes } } = data;
  const { indexNode } = breakDownAllNodes(nodes);
  const { frontmatter: { header, subheader, text, jumpToAnchorText } } = indexNode;

  let extraInfoPart;
  if (jumpToAnchorText) {
    extraInfoPart = (
      <div>
        <Link
          to='products/'
          className="btn btn-xl btn-danger text-uppercase"
        >{jumpToAnchorText}</Link>
      </div>
    );
  }

  return (
    <Wrapper
      pathContext={pathContext}
      nodes={nodes}
      node={indexNode}
      className='my-auto'
    >
      <ImageCard
        header={header}
        subheader={subheader}
        text={text}
        extraInfo={extraInfoPart}
      />
    </Wrapper>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  pathContext: PropTypes.object,
};

IndexPage.defaultProps = {
  pathContext: {
    langKey: "de",
    defaultLang: "de",
    langTextMap: {},
  },
};

export default IndexPage;
