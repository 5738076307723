import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Container, Card } from "react-bootstrap";

import nl2br from "utils/nl2br";

import "./ImageCard.scss";

const ImageCard = ({
  className, header, subheader, extraInfo, text
}) => (
  <Card
    className={clsx("image-card bg-transparent text-center border-0 py-5", className)}
  >
    <Card.Body className="no-padding d-flex align-items-center py-4">
      <Container>
        <div className="intro-text overflow-hidden d-inline-flex flex-column">
          <div
            className="intro-heading text-uppercase justify-content-center"
            dangerouslySetInnerHTML={{ __html: nl2br(header) }}
          />
          <div
            className="intro-lead-in text-uppercase"
            dangerouslySetInnerHTML={{ __html: nl2br(subheader) }}
          />
          <div
            className="intro-lead-in-text"
            dangerouslySetInnerHTML={{ __html: nl2br(text) }}
          />
          {extraInfo}
        </div>
      </Container>
    </Card.Body>
  </Card>
);

ImageCard.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  text: PropTypes.string,
  extraInfo: PropTypes.any,
};

ImageCard.defaultProps = {
  className: null,
  header: "",
  subheader: "",
  text: "",
  extraInfo: null,
};

export default ImageCard;
